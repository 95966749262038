//
// Module Cover
//

.module-cover {
    position: relative;
    padding: $module-cover-padding-top 0 $module-cover-padding-bottom;
}

@media (min-width: 768px) {
    .module-cover,
    .module-cover-slides {
        .carousel-inner{ 
            h2, .h2 {
            font-size: 3.75rem;
            }
        }
    }
}

@media (min-width: 544px) {
    .module-cover,
    .module-cover-slides {
        .carousel-inner{ 
            h2, .h2 {
                font-size: 2.5rem;
            }
        }
    }
}

.module-cover,
.module-cover-slides {
    font-size: rem(22px);


    h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
    }

    h1, .h1 {
        font-weight: 500;
        font-size: rem(82px);
    }

    h2, .h2 {
        font-size: 2rem;
    }

    h3, .h3 {
        font-size: rem(48px);
    }

    h4, .h4 {
        font-size: rem(38px);
    }

    h5, .h5 {
        font-size: rem(24px);
    }

    h6, .h6 {
        font-size: rem(15px);
    }

    .btn {
        margin: 2px 10px 2px 0;

        .text-right & {
            margin: 2px 0 2px 10px;
        }

        .text-center & {
            margin: 2px 5px;
        }
    }

    .btn-lg, .btn-group-lg > .btn {
        padding: 1.1875rem 2.25rem;
        font-size: 1.25rem;
        line-height: 1.5;
    }

    .breadcrumb {
        padding: 0;
    }

    .container > .row > [class*=col] > *:last-child {
        margin-bottom: 0;
    }
}

.fullscreen {
    padding: 0;
    height: 100vh;

    .container,
    .container > .row {
        height: 100%;
    }

    .container > .row {
        align-items: center;
    }
}


//
// Media
//

@include media-breakpoint-down(md) {

    .module-cover {
        min-height: auto;
        height: auto;
        padding: $module-mobile-padding-top + $header-height 0 $module-mobile-padding-bottom;
    }

    .module-cover.fullscreen {
        min-height: auto;
        height: auto;
        padding: 120px 0 120px;
    }

    .module-cover,
    .module-cover-slides {

        h1 {
            font-size: rem(42px);
        }

        h2 {
            font-size: rem(38px);
        }

        h3 {
            font-size: rem(34px);
        }

        h4 {
            font-size: rem(30px);
        }
    }
}

@include media-breakpoint-down(sm) {

    .module-cover,
    .module-cover-slides {
        font-size: rem(14px);

        h1 {
            font-size: rem(32px);
        }

        h2 {
            font-size: rem(28px);
        }

        h3 {
            font-size: rem(24px);
        }

        h4 {
            font-size: rem(20px);
        }

        h5 {
            font-size: rem(16px);
        }

        h6 {
            font-size: rem(14px);
        }

        br {
            display: none;
        }
    }

}