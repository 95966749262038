
//
// gallery
//

.gallery {

    .image-loader:not(:empty) {
        height: 100%;
    }

    .gallery-panel {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
            .gallery-nav-prev.active {
                display: block;
            }

            .gallery-nav-next.active {
                display: block;
            }
        }

        .gallery-close {
            display: none;
        }

        .gallery-nav {
            border: 1px solid #eee;
            position: absolute;
            z-index: 10;
            padding-top: 5px;
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 5px;
            background-color: white;
            color: #666666;
            cursor: pointer;
            font-size: 25px;

            i {
                font-size: 25px;
                line-height: 1.7;
                vertical-align: middle;
                margin-bottom: 2px;
            }
        }

        .gallery-nav-prev {
            left: 0px;
            display: none;
        }

        .gallery-nav-next {
            right: 0px;
            display: none;
        }

        .shop-single-item-photos {
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: hidden;
            overflow-y: hidden;
            margin-bottom: 10px;

            & > .photo {
                flex: 0 0 auto;
                align-items: center;
                width: 100%;
                height: 300px;

                a {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        margin: auto;
                        display: block;
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .gallery-menu {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
            .gallery-nav-prev.active {
                display: block;
            }

            .gallery-nav-next.active {
                display: block;
            }
        }

        .gallery-nav {
            border: 1px solid #eee;
            position: absolute;
            z-index: 10;
            padding-top: 1px;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 1px;
            background-color: white;
            color: #666666;
            cursor: pointer;

            i {
                font-size: 25px;
                line-height: 1.7;
            }
        }

        .gallery-nav-prev {
            left: 0px;
            display: none;
        }

        .gallery-nav-next {
            right: 0px;
            display: none;
        }

        .shop-single-item-gallery {
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: hidden;
            overflow-y: hidden;

            & > div.menu-item {
                padding-left: 5px;
                padding-right: 5px;
                flex: 0 0 auto;
                width: 30%;
                height: 100px;
                align-items: center;
                padding-bottom: 2px;
                border-bottom: 4px solid transparent;

                &.active {
                    border-bottom: 4px solid #e20014;
                }

                a {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                        margin: auto;
                    }
                }
            }
        }
    }

    &.full-screen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        margin: 10px;
        border: 1px solid #aaaaaa;
        border-radius: 10px;
        padding: 20px;
        z-index: 99;

        .gallery-panel {
            width: 100%;
            height: 100%;
            padding-bottom: 120px;

            .gallery-close {
                display: block;
                position: absolute;
                right: -10px;
                top: -10px;
                border-radius: 50%;
                padding-top: 2px;
                padding-bottom: 2px;
                padding-right: 13.5px;
                padding-left: 13.5px;
                z-index: 101;
                cursor: pointer;

                &:hover {
                    color: #e20014;
                }
            }

            .shop-single-item-photos {
                width: 100%;
                height: 100%;

                & > .photo {
                    height: 100%;

                    a picture { 
                        margin: auto;
                        img.first {
                                max-width: 1200px;
                                max-height: 675px;
                        }
                    }
                }
            }
        }

        .gallery-menu {
            top: -110px;

            .shop-single-item-gallery {
                & > div.menu-item {
                    width: 10%;
                }
            }
        }
    }
}

.shop-single-item-title {
    margin: 0;
}

.shop-single-item-description {
    margin-bottom: 30px;
}

@include media-breakpoint-down(md) {

    .shop-single-item-photos {
        padding-left: 0;
    }

    .shop-single-item-gallery {
        position: static;
        width: auto;

        > li {
            display: inline-block;
            width: 90px;
            margin: 10px 10px 0 0;
        }
    }
}
