/**
    added full screen carousel
*/

.dropdown.language-dropdown .dropdown-menu.language-dropdown {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    max-height: 0;
    display: block;
    overflow: hidden;
    -ms-opacity: 0;
    opacity: 0;
    padding: 0;
}

.dropdown.show.language-dropdown .dropdown-menu.language-dropdown {
    max-height: 300px;
    -ms-opacity: 1;
    opacity: 1;
}

.dropdown-menu.language-dropdown {
    min-width: 7rem;
}

.extra-nav .dropdown-menu {
    margin: 0;
    border-radius: 0;
}

.inner-nav .sub-menu {
    margin-top: 0;
}

/* adds some margin below the link sets  */
.inner-nav .dropdown-menu div[class*="col"] {
    margin-bottom: 1rem;
}

.inner-nav .dropdown-menu {
    border: none;
}

/* breakpoint and up - mega dropdown styles */
@media screen and (min-width: 992px) {

    /* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
    .inner-nav .nav-item {
        padding: .5rem .5rem;
        margin: 0 .25rem;
    }

    .menu-item-has-children {
        position: static;
    }

    /* makes the dropdown full width  */
    .inner-nav .dropdown {
        position: static;
    }

    .inner-nav .dropdown-menu {
        width: 100%;
        left: 0;
        right: 0;
        /*  height of nav-item  */
        top: 85px;
    }

    /* shows the dropdown menu on hover */
    .inner-nav .dropdown:hover .dropdown-menu, .navbar .dropdown .dropdown-menu:hover {
        display: block !important;
    }

    .inner-nav .dropdown-menu {
        border: 1px solid rgba(0,0,0,.15);
        background-color: #fff;
    }
}

.product-category-info > li::before {
    content: "+";
    color: black;
    font-weight: bold;
    display: inline-block;
    width: 1em;
}

#press-module {
    padding-top: 100px;
    padding-bottom: 0;
}
