﻿@import "node_modules/bootstrap/scss/mixins";
@import "mixins";



@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";

@import "node_modules/bootstrap/scss/utilities/display";
@import "node_modules/bootstrap/scss/utilities/screenreaders";


@import "./type.scss";
@import "./nav.scss";
@import "./header.scss";
@import "./header-sub-menu.scss";
@import "./modules.scss";
@import "./module-cover.scss";


// some part of node_modules/bootstrap/scss/utilities/spacing
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $prop, $abbrev in (margin: m, padding: p) {
            @each $size, $length in $spacers {
                .#{$abbrev}t#{$infix}-#{$size} {
                    #{$prop}-top: $length !important;
                }

                .#{$abbrev}r#{$infix}-#{$size} {
                    #{$prop}-right: $length !important;
                }

                .#{$abbrev}b#{$infix}-#{$size} {
                    #{$prop}-bottom: $length !important;
                }

                .#{$abbrev}l#{$infix}-#{$size} {
                    #{$prop}-left: $length !important;
                }
            }
        }
    }
}