@import "../../../Common Javascript Files/scss/fonts.scss";
@import "custom/functions.scss";
@import "../../node_modules/bootstrap/scss/functions.scss";
@import "../../node_modules/bootstrap/scss/variables.scss";
@import "variables.scss";

@import "custom/bootstrap-core.scss";
@import "custom/shop.scss";


*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0; // 1
    font-family: $font-family-base;
    @include font-size($font-size-base);
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: $body-color;
    text-align: left; // 3
    background-color: $body-bg; // 2
}

.text-center {
    text-align: center !important;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.btn {
    display: inline-block;
    border: 2px solid transparent;
    text-transform: uppercase;
    line-height: 1.5;
    letter-spacing: $btn-letter-spacing;
    font-size: $btn-font-size;
    font-weight: 600;
}

.newsletter-button {
    position: fixed;
    left: 30px;
    bottom: 20px;
    background-color: #0aaddc;
    border-color: #0aaddc;
    width: 80px;
    height: 60px;
    border-radius: 30px;
    z-index: 98;

    .newsletter-glyph {
        bottom: 38px;
        left: 80px;
    }

    .alert-message {
        position: fixed;
        left: 50px;
        bottom: 70px;
        background-color: #0aaddc;
        border: 3px solid #0aaddc;
        padding: 10px;
        padding-left: 30px;
        width: 230px;
        height: 85px;
        border-radius: 30px;
        font-size: 17px;

        i {
            height: 24px;
            width: 24px;
        }
    }

    div.a[data-lightbox-href] {
        color: #fff;
        width: 24px;
        height: 24px;
        display: block;
        margin: 8px;

        &:hover {
            color: inherit;
        }
    }
}

.alert-on-bottom {
    position: fixed !important;
    bottom: 0;
    margin: 0px !important;
    width: 100%;
    z-index: 20;
    padding: 10px 20px !important;

    p {
        margin-bottom: 0;
        margin-right: 90px;
    }

    a {
        color: gray;
    }

    .btn-dark {
        position: absolute;
        right: 10px;
        bottom: 7px;
        padding: 5px 10px;
        text-transform: uppercase;
        cursor: pointer;
    }

    .cookie-accept-col {
        height: 32px;
    }

    .cookie-accept-btn {
        bottom: 0 !important;
        margin-right: calc(50% - 40px);
    }
}

.parallax-panel {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.parallax-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.parallax-layer-foreground {
    transform: translateZ(0);
}

.parallax-layer-background {
    margin-top: 0;
    padding-top: $header-height;
    transform: translateZ(-1px) scale(2);
    overflow: hidden;

    img {
        position: absolute;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
    }

    &.ratio_1 {
        img {
            -webkit-transform: translate(-50%, -25%);
            transform: translate(-50%, -25%);
        }
    }

    &.ratio_3 {
        padding-top: $header-height * 1.5;
        transform: translateZ(-1px) scale(3);

        img {
            top: 16.5%;
        }
    }

    &.ratio_4 {
        transform: translateZ(-1px) scale(4);
        padding-top: $header-small-height * 2;

        img {
            top: 25%;
        }
    }

    &.ratio_5 {
        transform: translateZ(-1px) scale(5);
        padding-top: $header-small-height * 2.5;

        img {
            top: 30%;
        }
    }

    &.ratio_6 {
        transform: translateZ(-1px) scale(6);
        padding-top: $header-small-height * 3;

        img {
            top: 33.3%;
        }
    }

    &.ratio_7 {
        transform: translateZ(-1px) scale(7);
        padding-top: $header-small-height * 3.5;

        img {
            top: 35.5%
        }
    }

    &.ratio_8 {
        transform: translateZ(-1px) scale(8);
        padding-top: $header-small-height * 4;

        img {
            top: 37.5%
        }
    }

    &.ratio_9 {
        transform: translateZ(-1px) scale(9);
        padding-top: $header-small-height * 4.5;

        img {
            top: 38.8%
        }
    }

    &.ratio_10, &.ratio_11, &.ratio_12, &.ratio_13, &.ratio_14, &.ratio_15, &.ratio_16, &.ratio_17, &.ratio_18, &.ratio_19, &.ratio_20 {
        transform: translateZ(-1px) scale(10);
        padding-top: $header-small-height * 5;

        img {
            top: 40%;
        }
    }
}

.image-loader {
    width: 100%;
    position: relative;

    .image-place-holder {
        width: 100%;
        background-color: $partex-image-loading-color;
        padding-top: 56.24%;
        z-index: 3;
    }

    .image-preview {
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 4;
        opacity: 0.0;
        display: flex;
        height: 100%;
    }
}

.btn-partex,
.btn-logo-blue,
.btn-logo-yellow {
    position: absolute;
    bottom: 2px;
    left: 0;
}

.btn-partex-in-text,
.btn-logo-yellow-in-text,
.btn-logo-blue,
.btn-logo-yellow,
.btn-partex,
.btn.btn-partex {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: rem(20px);
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    border: none;
    padding: 5px;

    &.disabled, &:disabled {
        opacity: 0.65;
    }
}

section.module {
    padding-bottom: 2.1rem;
    padding-top: 1rem;
}

section.module .section-link,
section.module.section-link,
.scroll-link {
    margin-top: -60px;
    padding-top: 60px;
}

.login-dropdown-link {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    display: block;
    top: 55%;
    cursor: pointer;

    i {
        font-size: 28px;
    }
}

#cart-dropdown-menu, #login-dropdown-menu {
    visibility: hidden;

    i {
        min-width: 28px;
    }
}

.cart-dropdown-link {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    display: block;
    top: 55%;
    cursor: pointer;

    i {
        font-size: 28px;
    }
}

#cart-label-count {
    font-size: 15px;
    font-weight: 700;
    background: $partex-red;
    color: #fff;
    padding: 2px 6px;
    vertical-align: top;
    margin: -7px 0 0 -10px;
    border-radius: 10px;
}

ol.breadcrumb {
    margin-bottom: $breadcrumb-margin-bottom;
    @include font-size($breadcrumb-font-size);

    .breadcrumb-item {
        display: flex;
    }
}

img {
    max-width: 100%;
    height: auto;
}

.dropdown-menu {
    display: none;
}

.inner-nav .sub-menu li.nav-item > a {
    font-size: rem(14px);
}

.inner-nav .dropdown-menu.language-dropdown {
    background-color: #fff;
}

.inner-nav .sub-menu {
    margin-top: 0;
}

.inner-nav .menu-item-has-children .sub-menu {
    min-height: 150px;
    width: 100%;
    left: 0px;
}

.inner-nav > ul li.menu-item-has-children .sub-menu {
    -webkit-transform: none;
    transform: none;
    transition: none;
}

.inner-navigation,
.inner-navigation.collapsing {
    position: absolute;
    right: 0px;
    left: 0px;
}

.inner-nav > ul {
    height: 100%;
    padding: 0;
    margin: 0;

    > li {
        position: static;
    }
}

.dropdown-menu.nav-menu-dropdown {
    margin: 0px;
    border-radius: 0px;
}

.language-dropdown .flags {
    flex: 0 0 50%;
    max-width: 50%;
}

.inner-nav .sub-menu .category-header {
    letter-spacing: 0.05625rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 15px 0px;
    color: inherit;
}

.inner-nav .sub-menu li > a {
    padding: 0.2rem 0.1rem;
}

.inner-nav .sub-menu .other-categories li > a {
    letter-spacing: 0.05625rem;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
}

.inner-nav .sub-menu a.nav-category-link {
    color: #222222;

    .image-loader {
        width: 100%;

        .image-place-holder {
            width: 100%;
            padding-top: 100%;
        }

        .loader-dual-ring {
            width: 32px;
            height: 32px;
            margin-top: -17px;
            margin-left: -16.5px;

            &:after {
                width: 32px;
                height: 32px;
                border-width: 3px;
            }
        }
    }

    .img-24 {
        .image-loader {
            width: 24px;
            height: 24px;
            display: inline-block;
            vertical-align: middle;

            .image-place-holder {
                width: 24px;
                height: 22px;
            }

            .loader-dual-ring {
                width: 16px;
                height: 16px;
                margin-top: -8px;
                margin-left: -7px;

                &:after {
                    width: 16px;
                    height: 16px;
                    border-width: 2px;
                }
            }
        }
    }
}

.svg-24 svg {
    width: 24px;
    height: 24px;
}


.inner-nav .sub-menu a:hover.nav-category-link,
.inner-nav .sub-menu a:hover.nav-category-link .h6 {
    color: rgba(157, 157, 157, 0.65);
}

@media (max-width: 1199px) {
    .inner-nav .sub-menu a.nav-category-link {
        color: darkgray;
    }
}

.inner-nav .sub-menu a:hover.nav-category-link h6 {
    color: rgba(157, 157, 157, 0.65);
}

.inner-nav div.sub-menu {
    margin-bottom: 0;
    padding-bottom: 0;
}

.inner-nav .menu-item-has-children div.sub-menu {
    min-height: 100px;
}

.extra-nav > ul > li > a {
    letter-spacing: $header-link-letter-spacing;
    text-transform: $header-link-text-transform;
    font-weight: $header-link-font-weight;
    font-size: $header-icon-font-size;

    & > .menu-item-span.main-menu {
        top: 55%;
    }
}

.module-cover-small {

    h1 {
        font-weight: 600;
        font-size: 3.50rem;
    }

    .description {
        font-size: 1.75rem;
        line-height: 2.15rem;
    }

    .header-image {
        width: 100vw;
    }
}

.inner-brand-logo {
    height: 28px;
}

.inner-brand-75 {
    height: 50px;
}

.box-logo-yellow {
    background-color: $partex-yellow;
}

.breadcrumb {
    display: flex;
}

section.product-page-title {
    padding-bottom: 6px;
    background-color: #e5e5e5;

    .breadcrumb {
        justify-content: flex-start;
    }

    .breadcrumb li.breadcrumb-item,
    .breadcrumb li.breadcrumb-item > a,
    .breadcrumb-item + .breadcrumb-item::before {
        font-size: rem(16px);
        color: black;
    }

    .breadcrumb .breadcrumb-item:hover,
    .breadcrumb li.breadcrumb-item > a:hover {
        color: #788487;
    }
}

.line-height-small {
    line-height: 1.3;
}

.line-height-normal {
    line-height: 1.1;
}

.line-height-medium {
    line-height: 1.2;
}

.material-icons-outlined.home::before {
    width: 24px;
    height: 24px;
}
