//
// Header Sub-menu
//

.inner-nav .sub-menu {
    position: absolute;
    background: $submenu-background;
    border-radius: $submenu-border-radius;
    box-shadow: $submenu-box-shadow;
    min-width: $submenu-min-width;
    left: 10px;
    margin-top: 5px;
    visibility: hidden;
    text-align: left;
    padding: 15px 0;
    opacity: 0;
    list-style: none;

    .sub-menu {
        top: -15px;
        left: 100%;
        margin: 0 4px;
    }

    li {
        position: relative;
        padding: 0;
        margin: 0;

        &.menu-item-has-children > a::after {
            display: block;
            position: absolute;
            right: 20px;
            top: 50%;
            margin-top: -4px;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            font-size: rem(8px);
            transition: all .3s ease-in-out;
        }
    }
}

//
// Sub-menu links
//

.inner-nav .sub-menu li > a {
    position: relative;
    padding: 8px 20px;
    display: block;
    text-transform: $submenu-text-transform;
    letter-spacing: $submenu-letter-spacing;
    font-weight: $submenu-font-weight;
    font-size: $submenu-font-size;
    color: $submenu-link-clr;

    > i,
    > span {
        font-size: $submenu-font-size;
        margin-right: 10px;
    }
}

.inner-nav .sub-menu li > a:hover,
.inner-nav .sub-menu li.sub-menu-open,
.inner-nav .sub-menu li.sub-menu-open > a {
    color: $submenu-link-clr-hvr;
}

li.menu-item-has-children .sub-menu a {
    transition: none;
}

//
// Sub-menu transitions
//

.inner-nav .sub-menu-open > .sub-menu {
    visibility: visible;
    opacity: 1;
}

.inner-nav > ul li.menu-item-has-children .sub-menu {
    transform: translateY(5px);
    transition: opacity 0.3s 0.1s, transform 0.3s 0.1s, visibility 0.3s 0.1s;
}

.inner-nav > ul > li.menu-item-has-children.sub-menu-open > .sub-menu,
.inner-nav > ul > li.menu-item-has-children.sub-menu-open > .sub-menu > .sub-menu-open > .sub-menu {
    transform: translateY(0px);
}


//
// Media
//

@media (max-width: 1199px) {

    .inner-nav .sub-menu {
        background: $header-small-screen-background;
        color: $header-transparent-link-clr;
        box-shadow: none;
        padding: 0 rem(10px);
        display: none;

        .sub-menu {
            padding: 0 rem(10px);
            display: none;
        }

        margin: 0;
    }

    .inner-nav .sub-menu-open > .sub-menu {
        position: static;
        display: block!important;
    }

    .inner-nav .sub-menu li > a {
        padding: rem(10px) 0;
        color: $header-transparent-link-clr;
    }

    .inner-nav .sub-menu li > a:hover,
    .inner-nav .sub-menu li.sub-menu-open,
    .inner-nav .sub-menu li.sub-menu-open > a {
        color: $header-transparent-link-clr-hvr;
    }
}
