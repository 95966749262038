﻿
// Partex Colors

$partex-yellow: #FFD22D;
$partex-gray: #F5F5F5;
$partex-blue: #099DDC;
$partex-red: #E20014;
$partex-image-loading-color: #F5F5F5;

$body-color: #000000;
$border-color: #9c9d9f;
$border-radius: 0;
$text-muted: #a3a6a8;

// Footer

$footer-padding-y: 90px;
$footer-background: #cccccc;
$footer-headings-color: #FFFFFF;
$footer-link-color: #000000;

// Modules/Section

$module-cover-padding-top: 180px;
$module-cover-padding-bottom: 90px;
$module-mobile-padding-top: 70px;
$module-mobile-padding-bottom: 40px;
$module-padding-top: 120px;
$module-padding-bottom: 100px;
$module-page-title-padding-top: 6px;
$module-page-title-padding-bottom: 45px;
$module-gray-background: $partex-gray;
$module-overlay-color: #000000;
