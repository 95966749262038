﻿@font-face {
    font-family: 'Partex Marking';
    src: url('../../../Common Javascript Files/fonts/partex-marking_regular.woff2') format('woff2'), url('../../../Common Javascript Files/fonts/partex-marking_regular.woff') format('woff');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Partex Marking';
    src: url('../../../Common Javascript Files/fonts/partex-marking_bold.woff2') format('woff2'), url('../../../Common Javascript Files/fonts/partex-marking_bold.woff') format('woff');
    font-display: swap;
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Partex Marking';
    src: url('../../../Common Javascript Files/fonts/partex-marking_italic.woff2') format('woff2'), url('../../../Common Javascript Files/fonts/partex-marking_italic.woff') format('woff');
    font-weight: normal;
    font-display: swap;
    font-style: italic;
}
